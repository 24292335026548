import React, { Fragment, useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXDoubleButtonFooter from "../../components/layout/footer/hailcx-double-button-footer"
import {
  EuiDescriptionList,
  EuiImage,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@elastic/eui"
import Images from "../../images"
import { navigate } from "gatsby-link"
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores"
import { RepairTypes } from "../../entities/constants"
import SummaryItem from "../../entities/summaryItems"
import "../../styles/hailcx-description-lists.css"
import {
  getBookingDateTimeUIFormatted,
  getCompletionDateUIFormatted,
} from "../../entities/utilities"
import HailCXRepairTypeAlert from "../../components/hailcx-repairtype-alert"
import SummaryRepairLocation from "../../components/booking/summary-repairlocation"
import StopIcon from '../../images/stop-icon.png';
import HourglassImg from '../../images/hourglass.gif';
import { size } from "@elastic/eui/src/global_styling/variables/_size"
import HailCXSingleButtonFooter from "../../components/layout/footer/hailcx-single-button-footer"

const BookingTechnicianConfirmationPage = () => {
  const [bookingSummary, setBookingSummary] = useState<SummaryItem[]>([])
  const { facilityStore, bookingStore, waitlistStore } = useStore()
  const { facility } = facilityStore
  const { booking, bookingResult } = bookingStore

  const data = {
    title: "Technician check",
    primaryContentAccordionText: "Has the technician confirmed to proceed?",
    primaryContent: "Confirmed to proceed",
    secondaryContent: "Choose another time",
  }

  const proceedToBookingConfirmed = () => {    
    navigate("/book/booking-confirmed")
  }


  return (
    <HailCXLayout
      header={<HailCXRightImageHeader title={data.title} progressValue={85} />}
      footer={
        <HailCXSingleButtonFooter/>
      }
    >
      <EuiText
        grow={false}
        className="eui-textLeft"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      >
        <h1><EuiImage src={StopIcon} alt="Stop" style={{width:'70px'}} />Almost done</h1>
        <p style={{color:"red", fontSize:"1.1em", marginLeft:"70px"}}>Please wait for your technician...</p>

        <a onClick={() => proceedToBookingConfirmed()}><img src={HourglassImg} alt="Waiting for technician" style={{width:'160px', height: '160px', marginRight: 'auto', marginLeft: 'auto', marginTop: '50px'}} /></a>
      </EuiText>
      
      
    </HailCXLayout>
  )
}

export default observer(BookingTechnicianConfirmationPage)
